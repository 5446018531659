import useAsyncFetch from "./useAsyncFetch";
import useIsGuest from "./useIsGuest";

const useRewardsData = ({
	immediate = true,
	server = false,
	cached = true,
	lazy = true
}: { immediate?: boolean; server?: boolean; cached?: true; lazy?: boolean } = {}) => {
	const isGuest = useIsGuest();

	const asyncData = useAsyncFetch({
		path: "/rest/player/rewards/",
		method: "get",
		options: {
			server,
			lazy,
			cached,
			immediate: immediate && !isGuest.value,
			watch: [isGuest],
			default: () => ({
				data: {
					email: {
						available: false,
						completed: false,
						coins: 0
					},
					profile: {
						available: false,
						completed: false,
						coins: 0
					},
					phone: {
						available: false,
						completed: false,
						coins: 0
					}
				}
			})
		}
	});

	const isRewardsAvailable = computed(() =>
		Object.values(asyncData.data.value.data).some((reward) => reward.available && !reward.completed)
	);

	const rewardsSum = computed(() =>
		Object.values(asyncData.data.value.data).reduce(
			(acc, { available, completed, coins }) => acc + (available && !completed ? coins : 0),
			0
		)
	);

	return { ...asyncData, rewardsSum, isRewardsAvailable };
};

export default useRewardsData;
